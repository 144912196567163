import Cookies from 'js-cookie'
import CookieConsentService from '@/services/cookie-consent.service'
const COOKIE_CONSENT_LEVEL = 'consentLevelCookie'

export default {
  namespaced: true,
  state: {
    policyModalOpen: false,
    modalOpen: false,
    bannerOpen: false,
    levelSelected: 3
  },
  mutations: {
    setModalOpen (state, value) {
      state.modalOpen = value
    },
    setPolicyModalOpen (state, value) {
      state.policyModalOpen = value
    },
    setBannerOpen (state, value) {
      state.bannerOpen = value
    },
    setLevelSelected (state, value) {
      state.levelSelected = value
    }
  },
  actions: {
    closePolicyModal ({ commit }) {
      commit('setPolicyModalOpen', false)
      commit('setModalOpen', true)
    },
    openPolicyModal ({ commit }) {
      commit('setModalOpen', false)
      commit('setPolicyModalOpen', true)
    },
    closeModal ({ commit }) {
      commit('setModalOpen', false)
      document.body.classList.remove('unscrollable-body')

    },
    openModal ({ commit }) {
      commit('setModalOpen', true)

      document.body.classList.add('unscrollable-body')
    },
    closeBanner ({ commit }) {
      commit('setBannerOpen', false)
    },
    async updateLevelSelected ({ commit }, payload) {
      commit('setLevelSelected', payload)
      Cookies.set(COOKIE_CONSENT_LEVEL, payload)
      await CookieConsentService.sendConsentLogs(payload, window.location.hostname)
    },
    async preloadCookies ({ commit }) {
      const cookieConsentLevel = Cookies.get(COOKIE_CONSENT_LEVEL)
      if (cookieConsentLevel) {
        commit('setLevelSelected', Number(cookieConsentLevel))
      } else {
        commit('setLevelSelected', 3)
        commit('setBannerOpen', true)
      }
    }
  }
}
