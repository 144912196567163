import { createInstance } from './axios.api'

export const frontendLoggerApi = createInstance(process.env.VUE_APP_FRONTEND_LOGGER_SERVICE_URL)
export const consentLogApi = createInstance(process.env.VUE_APP_CONSENT_LOG_SERVICE_URL)

const isDevEnvironment = process.env.NODE_ENV === 'development'
const TYPE_MODAL = 'Modal'

const sendConsentLog = async (cookieConsentLevel, domain) => {
  const payload = {
    'consentLevel': cookieConsentLevel,
    'type': TYPE_MODAL,
    'domain': domain
  }
  await consentLogApi.post('', payload)
}

const sendFrontendLog = async (cookieConsentLevel) => {
  const payload = {
    message: `Level: ${cookieConsentLevel} Type: ${TYPE_MODAL}`,
    title: 'Cookie consent',
    logLevel: 'INFO'
  }
  await frontendLoggerApi.post('', payload)
}

const sendConsentLogs = async (cookieConsentLevel, domain) => {
  if (isDevEnvironment) {
    return
  }

  await Promise.all([
    sendConsentLog(cookieConsentLevel, domain),
    sendFrontendLog(cookieConsentLevel)
  ])
}

export default {
  sendConsentLogs
}
